import {
    AgeFormula,
    CreditRating,
    I18n,
    Link,
    Material,
    PagedReq,
    ProposalLanguage
} from "../api/types";
import { plainToInstance, Transform } from "class-transformer";
import { plainToArray } from "../common/util/class-transform";

export enum ProductCompanyStatus {
    ENABLE = "ENABLE", // 啟用
    DISABLE = "DISABLE" // 禁用
}

export enum ProductCompanyRegion {
    HONGKONG = "HONGKONG",            // 香港
    MACAO = "MACAO",                  // 澳门
    CHINA = "CHINA",                  // 中国
    SINGAPORE = "SINGAPORE",          // 新加坡
    CANADA = "CANADA",                // 加拿大
    ASIA = "ASIA",                    // 亞洲
    BERMUDA = "BERMUDA",              // 百慕大
    NEWYORK = "NEWYORK",              // 紐約
    USA = "USA",              // 紐約
}

export class ProductCompanyRegions {
    static all(): ProductCompanyRegion[] {
        return [
            ProductCompanyRegion.HONGKONG,
            ProductCompanyRegion.MACAO,
            ProductCompanyRegion.CHINA,
            ProductCompanyRegion.SINGAPORE,
            ProductCompanyRegion.CANADA,
            ProductCompanyRegion.ASIA,
            ProductCompanyRegion.BERMUDA,
            ProductCompanyRegion.USA,
        ]
    }
}

export enum CompanyBusinessType {
    GENERAL = "GENERAL", // 一般保险
    LONG_TERM = "LONG_TERM", // 长期保险
    GENERAL_AND_LONG_TERM = "GENERAL_AND_LONG_TERM", // 一般&长期保险
    TRUST = "TRUST", // 信托
    OTHER = "OTHER", // 其他
}

/**
 * 公司列表項實體
 */
export class ProductCompany {
    code: string;           // 唯一編碼
    name: string;           // 公司名稱
    nameEnglish: string;    // 公司名稱
    @Transform((value) => plainToInstance(I18n, Object.assign(new I18n(), value.value)))
    nameI18n: I18n;
    @Transform((value) => plainToInstance(I18n, Object.assign(new I18n(), value.value)))
    fullNameI18n: I18n;     // 公司全稱
    @Transform((value) => plainToInstance(I18n, Object.assign(new I18n(), value.value)))
    shortNameI18n: I18n;     // 公司全稱
    @Transform((value) => plainToInstance(I18n, Object.assign(new I18n(), value.value)))
    complianceNameI18n: I18n;     // 公司全稱
    status: string;         // 公司狀態
    desc: string;           // 公司簡介
    descI18n: I18n;         // 公司簡介
    logo: string;           // 公司圖標
    website: string;        // 公司鏈接
    links: Link[];          // 公司鏈接
    ageFormula: AgeFormula; // 年齡計算方式
    ageNote: string;        // 年齡描述
    @Transform((value) => plainToInstance(I18n, Object.assign(new I18n(), value.value)))
    ageNoteI18n: I18n;      // 年齡描述多語言
    rank: number;           // 公司排序
    updateTime: number; // 更新時間
    region: ProductCompanyRegion; // 所在地区
    @Transform((value) => plainToArray(Material, value.value))
    files: Material[]; // 文件列表
    fileTags: string[];
    @Transform((value) => plainToArray(CreditRating, value.value))
    creditRatings: CreditRating[] = []; // 信用评级
    proposalLanguages: ProposalLanguage[]; // 计划书语言
    proposalFrequencySupported: boolean; // 计划书是否支持付款频率
    hot: boolean; // 是否热门
    rpaReminderSupported: boolean; // 是否支持RPA续保提醒
    rpaPendingSupported: boolean; // 是否支持RPA Pending
    rpaUnderwritingSupported: boolean; // 是否支持核保机器人
    reduceAmountTips: I18n[]; // “是否允许减少基本金额”提示文案
    businessType: CompanyBusinessType; // 业务类型
    internalRemark: string; // 内部备注
    checked: boolean = false;         // 是否勾選
}

export class ProductCompanySearch extends PagedReq {
    code: string;
    name: string;
    status: ProductCompanyStatus;
    hot: boolean;
    region: ProductCompanyRegion;
    businessType: ProductCompanyRegion;
}

