import { Component, EventEmitter, Input, OnInit, ViewContainerRef } from '@angular/core';
import {
    AgeFormula,
    CreditRating,
    I18n,
    Link,
    Material,
    MaterialModifyReq,
    MaterialUploadReq,
    ProductType
} from "../../api/types";
import { ProductService } from "../product.service";
import { PropertySelectOption } from 'app/shared/components/property-select/property-select.component';
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ProductCompanyRegionPipe } from "../../shared/pipes/product-company-region.pipe";
import { CompanyBusinessType, ProductCompany, ProductCompanyRegion, ProductCompanyRegions } from "../product-types";
import { PropertyTextEditType } from "../../shared/components/property-text/property-text-edit.component";
import { NzDrawerRef } from "ng-zorro-antd/drawer";
import { CompanyBusinessTypePipe } from "../../shared/pipes/company-business-type.pipe";
import {
    ContractMasterRateCreateComponent
} from "../../contract/contract-master-rate-create/contract-master-rate-create.component";
import { NzModalService } from "ng-zorro-antd/modal";
import {
    CompanyCreditRatingEditorComponent, CreditRatingEntity
} from "../components/company-credit-rating-editor/company-credit-rating-editor.component";
import { ToastrService } from "ngx-toastr";
import { DataService } from "../../shared/services/data.service";

@Component({
    selector: 'app-product-company-info',
    templateUrl: './product-company-info.component.html',
    styleUrls: ['./product-company-info.component.less']
})
export class ProductCompanyInfoComponent implements OnInit {

    @Input() company: ProductCompany;
    @Input() editing: boolean = false;
    loading: boolean = false
    spinning: boolean = false;
    edited: boolean = false;
    storageAmountTips: I18n[];
    ageFormulaOptions: PropertySelectOption[] = [];
    regionOptions: PropertySelectOption[] = [];
    businessTypeOptions: PropertySelectOption[] = [];

    websiteType = PropertyTextEditType.URL;
    descType = PropertyTextEditType.TEXT_AREA;

    companyUpdated: EventEmitter<ProductCompany> = new EventEmitter<ProductCompany>();

    drawerRef: NzDrawerRef<ProductCompanyInfoComponent, ProductCompany>;

    constructor(private viewContainerRef: ViewContainerRef,
                private productService: ProductService,
                private productCompanyRegionPipe: ProductCompanyRegionPipe,
                private companyBusinessTypePipe: CompanyBusinessTypePipe,
                private notification: NzNotificationService,
                public dataService: DataService,
                private modalService: NzModalService,
                private toastr: ToastrService) {
        this.ageFormulaOptions?.push(new PropertySelectOption('周岁', AgeFormula.CURRENT_BIRTHDAY));
        this.ageFormulaOptions?.push(new PropertySelectOption('上一次生日', AgeFormula.LAST_BIRTHDAY));
        this.ageFormulaOptions?.push(new PropertySelectOption('下一次生日', AgeFormula.NEXT_BIRTHDAY));
        this.ageFormulaOptions?.push(new PropertySelectOption('最近生日', AgeFormula.NEAREST_BIRTHDAY));

        let productCompanyRegions = ProductCompanyRegions.all();
        this.regionOptions = productCompanyRegions.map(region => {
            return new PropertySelectOption(this.productCompanyRegionPipe.transform(region), region)
        })


        let businessTypes: CompanyBusinessType[] = [CompanyBusinessType.GENERAL, CompanyBusinessType.LONG_TERM, CompanyBusinessType.GENERAL_AND_LONG_TERM, CompanyBusinessType.TRUST, CompanyBusinessType.OTHER]
        this.businessTypeOptions = businessTypes.map(productType => {
            return new PropertySelectOption(this.companyBusinessTypePipe.transform(productType), productType)
        })

    }

    ngOnInit(): void {
        this.getCompanyInfo();
    }

    onEdit(): void {
        this.editing = true;
    }

    onCancelEdit(): void {
        this.editing = false;
    }

    // 是否允許減少基本金額
    onEdited(): void {
        this.edited = true;
        this.storageAmountTips = this.company?.reduceAmountTips?.length > 0 ? [...this.company.reduceAmountTips] : []
    }

    onCancelEdited(): void {
        this.edited = false;
        this.company.reduceAmountTips = this.storageAmountTips
    }

    addBasic(): void {
        this.company.reduceAmountTips?.push(new I18n())
    }

    onDelete(index: number): void {
        this.company.reduceAmountTips?.splice(index, 1)
    }

    onSaveEdited(): void {
        let code = this.company.code;
        this.productService.companyUpdate(code, this.company)
            .subscribe(
                data => {
                    this.notification.success('保存公司設置成功', '');
                    this.loading = false;
                    this.edited = false;
                    this.company = data;
                },
                error => {
                    this.notification.error('保存公司設置失败', '');
                    this.loading = false;
                });
    }

    onSave(): void {
        this.loading = true;
        let code = this.company.code;
        this.productService.companyUpdate(code, this.company)
            .subscribe(
                data => {
                    this.notification.success('保存公司信息成功', '');
                    this.loading = false;
                    this.companyUpdated.emit(data);
                    this.drawerRef?.close();
                },
                error => {
                    this.notification.error('保存公司信息失败', '');
                    this.loading = false;
                });
    }

    onTimeUpdate(): void {
        this.loading = true;
        this.productService.companyUpdateTime(this.company.code)
            .subscribe(
                data => {
                    this.notification.success('更新時間成功', '');
                    this.getCompanyInfo();
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this.notification.error('更新時間失败', '');
                });
    }

    get uploadReq(): MaterialUploadReq {
        return MaterialUploadReq.fromCompany(this.company);
    }

    // onFilesChange(files: Material[]) {
    //     this.productService.companyFilesUpdate(this.company.code, files)
    //         .subscribe(
    //             data => {
    //                 this.getCompanyInfo();
    //             },
    //             error => {
    //                 this.getCompanyInfo();
    //             });
    // }

    onFileAdd(material: Material) {
        this.productService.companyFilesAdd(this.company.code, material)
            .subscribe(
                data => {
                },
                error => {
                });
    }

    onFileRemove(material: Material) {
        this.productService.companyFilesRemove(this.company.code, material)
            .subscribe(
                data => {
                },
                error => {
                });
    }

    onLinksChange(links: Link[]) {
        this.productService.companyLinksUpdate(this.company, links)
            .subscribe(
                data => {
                    this.getCompanyInfo();
                },
                error => {
                    this.getCompanyInfo();
                });
    }

    getCompanyInfo() {
        this.productService.companyInfo(this.company.code)
            .subscribe(
                data => {
                    if (data.nameI18n == null) {
                        data.nameI18n = new I18n();
                    }
                    if (data.ageNoteI18n == null) {
                        data.ageNoteI18n = new I18n();
                    }
                    if (data.descI18n == null) {
                        data.descI18n = new I18n();
                    }
                    this.company = data;
                },
                error => {
                });
    }

    onFileModified(materialModifyReq: MaterialModifyReq) {
        this.spinning = true;
        console.log('onFileModified')
        this.productService.modifyCompanyFile(this.company.code, materialModifyReq)
            .subscribe(
                data => {
                    this.spinning = false;
                    if (!data) {
                        this.notification.error('文件重命名失敗', materialModifyReq.file.fileName);
                    }
                    this.getCompanyInfo();
                },
                error => {
                    this.spinning = false;
                });
    }

    onCreditRatingEdit(index: number, creditRating: CreditRating): void {
        const entity = new CreditRatingEntity(index, creditRating);
        const modal = this.modalService.create({
            nzTitle: '信用评级编辑',
            nzCentered: true,
            nzWidth: 750,
            nzContent: CompanyCreditRatingEditorComponent,
            nzViewContainerRef: this.viewContainerRef,
            nzComponentParams: {
                entity
            },
            nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
            nzFooter: [
                {
                    label: '确定',
                    onClick: componentInstance => {
                        const creditRatingEntity = componentInstance.entity;
                        this.company.creditRatings[creditRatingEntity.index] = creditRatingEntity.creditRating;
                        this.productService.companyCreditRatingsUpdate(this.company.code, this.company.creditRatings)
                            .subscribe(
                                data => {
                                    modal.close();
                                    this.toastr.success('更新信用评级成功')
                                },
                                error => {
                                });
                    }
                }
            ]
        });
        const instance = modal.getContentComponent();
        modal.afterOpen.subscribe(() => {
        });
        // Return a result when closed
        modal.afterClose.subscribe(result => {
        });
    }

    onCreditRatingAdd(): void {
        const entity = new CreditRatingEntity(0, new CreditRating());
        const modal = this.modalService.create({
            nzTitle: '信用评级新建',
            nzCentered: true,
            nzWidth: 750,
            nzContent: CompanyCreditRatingEditorComponent,
            nzViewContainerRef: this.viewContainerRef,
            nzComponentParams: {
                entity
            },
            nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
            nzFooter: [
                {
                    label: '确定',
                    onClick: componentInstance => {
                        const creditRatingEntity = componentInstance.entity;
                        this.company.creditRatings.push(creditRatingEntity.creditRating);
                        this.productService.companyCreditRatingsUpdate(this.company.code, this.company.creditRatings)
                            .subscribe(
                                data => {
                                    modal.close();
                                    this.toastr.success('更新信用评级成功')
                                },
                                error => {
                                });
                    }
                }
            ]
        });
        const instance = modal.getContentComponent();
        modal.afterOpen.subscribe(() => {
        });
        // Return a result when closed
        modal.afterClose.subscribe(result => {
        });
    }

    onCreditRatingDelete(index: number, creditRating: CreditRating): void {
        this.modalService.confirm({
            nzTitle: '是否确定删除？',
            nzContent: '<b style="color: #ff0048; font-size: 16px">请谨慎删除，删除后将无法恢复</b>',
            nzOkText: '删除',
            nzOkType: 'primary',
            nzCancelText: '取消',
            nzOnOk: () => {
                this.loading = true;
                this.company.creditRatings.splice(index, 1);
                this.productService.companyCreditRatingsUpdate(this.company.code, this.company.creditRatings)
                    .subscribe(
                        data => {
                            this.loading = false;
                            this.toastr.success('删除信用评级成功')
                        },
                        error => {
                        });
            },
            nzOnCancel: () => {
            }
        });
    }
}
