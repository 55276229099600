<div *ngIf="company">
    <div class="profile-large-title" style="justify-content: space-between;">
        <div>
            <nz-avatar [nzShape]="'square'" [nzSize]="40" [nzSrc]="company.logo"></nz-avatar>
            <span style="margin-left: 10px; font-weight: bold; width: 100%; font-size: 28px;">{{ company.name }}</span>
            <div style="font-size: 12px;">
                資料檢查時間:
                {{company.updateTime | date: 'yyyy-MM-dd HH:mm'}}
            </div>
        </div>
        <div>
            <button nz-button appThrottleClick (throttleClick)="onTimeUpdate()"
                    [nzSize]="'small'">
                <span>保司資料更新檢查時間</span>
            </button>
        </div>
    </div>
    <nz-tabset [nzSize]="'large'" nzCentered>
        <nz-tab nzTitle="公司詳情">
            <app-property-text [title]="'公司代码'" [(value)]="company.code" [editable]="false"></app-property-text>

            <app-property-text [title]="'公司默認名稱'" [(value)]="company.name" [editable]="true"
                               [editing]="editing"></app-property-text>

            <app-property-text [title]="'英文名称'" [(value)]="company.nameEnglish" [editable]="true"
                               [editing]="editing">
            </app-property-text>

            <app-property-i18n [title]="'公司名称（多语言配置）'" [(value)]="company.nameI18n" [editable]="true"
                               [editing]="editing"></app-property-i18n>

            <app-property-i18n [title]="'公司全称（多语言配置）'" [(value)]="company.fullNameI18n" [editable]="true"
                               [editing]="editing"></app-property-i18n>

            <app-property-i18n [title]="'公司簡稱（多语言配置）'" [(value)]="company.shortNameI18n" [editable]="true"
                               [editing]="editing"></app-property-i18n>

            <app-property-i18n [title]="'公司合規名稱（多语言配置）'" [(value)]="company.complianceNameI18n"
                               [editable]="true"
                               [editing]="editing"></app-property-i18n>

            <app-property-select [title]="'所在地区'" [name]="company.region | productCompanyRegion"
                                 [(value)]="company.region" [editable]="true" [editing]="editing"
                                 [options]="regionOptions">
            </app-property-select>

            <app-property-select [title]="'业务类型'" [name]="company.region | productCompanyRegion"
                                 [(value)]="company.businessType" [editable]="true" [editing]="editing"
                                 [options]="businessTypeOptions">
            </app-property-select>

            <app-property-text [title]="'官网地址'" [(value)]="company.website" [editable]="true" [editing]="editing"
                               [type]="websiteType">
            </app-property-text>

            <app-property-text [title]="'公司排序（数值越小排名越前）'" [(value)]="company.rank" [editable]="true"
                               [editing]="editing"></app-property-text>

            <app-property-switch [title]="'是否热门'" [(value)]="company.hot" [editing]="editing"
                                 [editable]="true"></app-property-switch>

            <app-property-select [title]="'年龄计算方式'" [name]="company.ageFormula | productCompanyAgeFormula"
                                 [(value)]="company.ageFormula" [editable]="true" [editing]="editing"
                                 [options]="ageFormulaOptions">
            </app-property-select>

            <app-property-switch [title]="'计划书是否支持付款频率'"
                                 [(value)]="company.proposalFrequencySupported"
                                 [editing]="editing" [editable]="true">
            </app-property-switch>

            <app-property-switch [title]="'是否支持续保机器人'"
                                 [(value)]="company.rpaReminderSupported"
                                 [editing]="editing" [editable]="true">
            </app-property-switch>

            <app-property-switch [title]="'是否支持待办机器人'"
                                 [(value)]="company.rpaPendingSupported"
                                 [editing]="editing" [editable]="true">
            </app-property-switch>

            <app-property-switch [title]="'是否支持核保机器人'"
                                 [(value)]="company.rpaUnderwritingSupported"
                                 [editing]="editing" [editable]="true">
            </app-property-switch>

            <!--            <app-property-text [title]="'投保年齡描述（周歲、上一次生日年齡、下一次生日年齡、最接近的生日年齡）'" [(value)]="company.ageNote"-->
            <!--                [editable]="true" [editing]="editing"></app-property-text>-->

            <!--            <app-property-i18n [title]="'投保年齡描述（多语言配置）'" [(value)]="company.ageNoteI18n" [editable]="true"-->
            <!--                [editing]="editing"></app-property-i18n>-->


            <app-property-text [title]="'公司描述'" [(value)]="company.desc" [editing]="editing" [editable]="true"
                               [type]="descType"></app-property-text>

            <app-property-i18n-area [title]="'公司描述（多语言配置）'" [(value)]="company.descI18n" [editable]="true"
                                    [editing]="editing"></app-property-i18n-area>

            <app-property-text [title]="'内部备注'" [(value)]="company.internalRemark" [editing]="editing"
                               [editable]="true"
                               [type]="descType"></app-property-text>

            <div class="footer">
                <div nz-row *ngIf="!editing" style="width: 100%; height: 100%;">
                    <div nz-col nzSpan="24" class="footer-button-primary">
                        <a nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onEdit()">
                            <em nz-icon nzType="edit" nzTheme="outline"></em>
                            <span>编 辑</span>
                        </a>
                    </div>
                </div>
                <div nz-row *ngIf="editing" style="width: 100%; height: 100%;">
                    <div nz-col nzSpan="12" class="footer-button">
                        <a nz-button nzType="link" appThrottleClick (throttleClick)="onCancelEdit()" nzBlock>
                            <em nz-icon nzType="close" nzTheme="outline"></em>
                            <span>取 消</span>
                        </a>
                    </div>
                    <div nz-col nzSpan="12" class="footer-button-primary">
                        <a nz-button nzType="link" appThrottleClick (throttleClick)="onSave()" nzBlock>
                            <em nz-icon nzType="save" nzTheme="outline"></em>
                            <span>保 存</span>
                        </a>
                    </div>
                </div>
            </div>
        </nz-tab>
        <nz-tab [nzTitle]="'公司附件'">
            <app-material-editor [materials]="company.files" [uploadReq]="uploadReq" [spinning]="spinning"
                                 [tags]="company.fileTags"
                                 (materialUploaded)="onFileAdd($event)"
                                 (materialRemoved)="onFileRemove($event)"
                                 (materialModified)="onFileModified($event)"></app-material-editor>
        </nz-tab>

        <ng-template #companyFiles>
            <span style="font-size: 16px">公司附件</span>
            <nz-badge nzSize="small" [nzCount]="company.files.length" [nzOverflowCount]="99999">
            </nz-badge>
        </ng-template>

        <nz-tab nzTitle="相關鏈接">
            <app-links-editor [links]="company.links" (valueChange)="onLinksChange($event)"></app-links-editor>
        </nz-tab>
        <nz-tab nzTitle="信用评级">
            <div>
                <div *ngFor="let creditRating of company.creditRatings; let i = index">

                    <div class="ratings">
                        <div class="ratings-rating">
                            <span>{{ creditRating.rating }}</span>
                        </div>
                        <div class="ratings-desc">
                            <div class="ratings-agency">
                                <span>{{ creditRating.ratingAgency }}</span>
                            </div>
                            <div class="ratings-project">
                                <span>{{ creditRating.project }}</span>
                            </div>
                            <div class="ratings-time">
                                <span>{{ creditRating.time | date:'yyyy-MM-dd' }}</span>
                            </div>
                        </div>
                        <div class="ratings-file-actions">
                            <button nz-button nzType="primary" style="width: 100%; margin: 0 0 0 0;" appThrottleClick
                                    (throttleClick)="onCreditRatingEdit(i, creditRating)">
                                <span>{{ '编辑' | translate }}</span>
                            </button>
                            <button nz-button nzType="link" style="width: 100%; margin: 0 0 0 0; color: #a71d5d"
                                    appThrottleClick (throttleClick)="onCreditRatingDelete(i, creditRating)">
                                <span>{{ '删除' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <button nz-button nzType="link" style="width: 100%; margin: 30px 0 0 0;" appThrottleClick
                        (throttleClick)="onCreditRatingAdd()">
                    <span>{{ '新增' | translate }}</span>
                </button>
            </div>
        </nz-tab>
        <nz-tab nzTitle="公司設置">
            <div>是否允許減少基本金額（多語言）</div>
            <nz-empty *ngIf="(!company.reduceAmountTips || company.reduceAmountTips?.length == 0) && !edited"
                      nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
            <!-- 非编辑状态 -->
            <div *ngIf="company.reduceAmountTips && company.reduceAmountTips.length > 0 && !edited"
                 class="property-value">
                <div class="highlight-i18n" *ngFor="let highlight of company.reduceAmountTips; let i = index">
                    <div class="i18n-edit-centre">
                        <div class="i18n">
                            <div class="i18n-title">简体：</div>
                            <div class="i18n-value">{{ highlight.zhHansCN }}</div>
                        </div>
                        <div class="i18n">
                            <div class="i18n-title">繁体：</div>
                            <div class="i18n-value">{{ highlight.zhHantHK }}</div>
                        </div>
                        <div class="i18n">
                            <div class="i18n-title">英文：</div>
                            <div class="i18n-value">{{ highlight.enUS }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 编辑状态 -->
            <div *ngIf="company.reduceAmountTips && company.reduceAmountTips.length > 0 && edited"
                 class="property-value">
                <div class="highlight-i18n-edit" *ngFor="let highlight of company.reduceAmountTips; let i = index">
                    <div class="i18n-edit-centre">
                        <div class="i18n-edit">
                            <div class="i18n-title">简体：</div>
                            <div class="i18n-value">
                                <input type="text" class="i18n-input" style="width: 100%;"
                                       [(ngModel)]="highlight.zhHansCN" placeholder=""/>
                            </div>
                        </div>
                        <div class="i18n-edit">
                            <div class="i18n-title">繁体：</div>
                            <div class="i18n-value">
                                <input type="text" class="i18n-input" style="width: 100%;"
                                       [(ngModel)]="highlight.zhHantHK" placeholder=""/>
                            </div>
                        </div>
                        <div class="i18n-edit">
                            <div class="i18n-title">英文：</div>
                            <div class="i18n-value">
                                <input type="text" class="i18n-input" style="width: 100%;" [(ngModel)]="highlight.enUS"
                                       placeholder=""/>
                            </div>
                        </div>
                    </div>
                    <div class="i18n-edit-right">
                        <a nz-button nzType="link" nzBlock
                           appThrottleClick (throttleClick)="onDelete(i)"
                           nzSize="small" class="delete-button">
                            <em nz-icon nzType="delete" nzTheme="outline"></em>
                        </a>
                    </div>
                </div>
            </div>

            <a *ngIf="edited" nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="addBasic()"
               nzSize="small" style="margin-top: 20px">
                <em nz-icon nzType="plus" nzTheme="outline"></em>
                <span style="font-size: 11px">添加</span>
            </a>

            <div class="footer">
                <a *ngIf="!edited" nz-button nzType="link" nzBlock appThrottleClick (throttleClick)="onEdited()"
                   nzSize="large">
                    <em nz-icon nzType="edit" nzTheme="outline"></em>
                    <span>編 輯</span>
                </a>
                <div nz-row *ngIf="edited" style="width: 100%; height: 100%;">
                    <div nz-col nzSpan="12" class="footer-button">
                        <a nz-button nzType="link" appThrottleClick (throttleClick)="onCancelEdited()" nzBlock
                           nzSize="large">
                            <em nz-icon nzType="close" nzTheme="outline"></em>
                            <span>取 消</span>
                        </a>
                    </div>
                    <div nz-col nzSpan="12" class="footer-button-primary">
                        <a nz-button nzType="link" appThrottleClick (throttleClick)="onSaveEdited()" nzBlock
                           nzSize="large">
                            <em nz-icon nzType="save" nzTheme="outline"></em>
                            <span>保 存</span>
                        </a>
                    </div>
                </div>
            </div>
        </nz-tab>
    </nz-tabset>
</div>
