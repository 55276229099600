import { Injectable, OnInit } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpService } from '../api/http.service';
import {
    ApiResponse, CreditRating, Knowledge,
    Link,
    Material,
    MaterialModifyReq,
    MedicalOptions,
    PagedResp,
    Product,
    ProductCategory,
    ProductFeature,
    ProductRestriction,
    ProductRestrictionSearch,
    ProductSearch,
    ProductStatistic,
    ProductTag,
    ProductTagSearch
} from "../api/types";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";
import { isNullOrUndefined } from "../common/utils";
import { ProductCompany, ProductCompanySearch } from "./product-types";
import { plainToInstance } from "class-transformer";
import { HttpParamEncoder } from "../common/encoder/http-param.encoder";

@Injectable()
export class ProductService extends BaseService implements OnInit {

    _companies: ProductCompany[] = [];
    _categories: ProductCategory[] = [];

    constructor(private http: HttpService) {
        super();
    }

    ngOnInit(): void {
    }

    /**
     * 获取公司列表
     */
    companies(search: ProductCompanySearch = new ProductCompanySearch()): Observable<ProductCompany[]> {
        let params = new HttpParams({encoder: new HttpParamEncoder()});        if (!isNullOrUndefined(search.region)) {
            params = params.set('region', search.region.toString());
        }
        return this.http.get<ApiResponse<ProductCompany[]>>(this.productUrl + '/company/all', {
            params: params,
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            this._companies = data.body.data;
            return this._companies
        }));
    }

    /**
     * 获取险种列表
     */
    categories(): Observable<ProductCategory[]> {

        // 返回缓存
        // if (this._categories.length > 0) {
        //     return of(this._categories);
        // }

        return this.http.get<ApiResponse<ProductCategory[]>>(this.productUrl + '/category/all', {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            this._categories = data.body.data;
            return this._categories
        }))
    }

    /**
     * 获取产品列表
     * @param search 搜索参数
     */
    productList(search: ProductSearch): Observable<PagedResp<Product>> {
        let params = new HttpParams({encoder: new HttpParamEncoder()});
        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        if (!isNullOrUndefined(search.name)) {
            params = params.set('name', search.name.toString());
        }
        if (!isNullOrUndefined(search.code)) {
            params = params.set('code', search.code.toString());
        }
        if (!isNullOrUndefined(search.type)) {
            params = params.set('type', search.type.toString());
        }
        if (!isNullOrUndefined(search.status)) {
            params = params.set('status', search.status.toString());
        }
        if (!isNullOrUndefined(search.categoryCode)) {
            params = params.set('categoryCode', search.categoryCode.toString());
        }
        if (!isNullOrUndefined(search.companyCode)) {
            params = params.set('companyCode', search.companyCode.toString());
        }
        if (!isNullOrUndefined(search.standard)) {
            params = params.set('standard', search.standard.toString());
        }
        if (!isNullOrUndefined(search.comparable)) {
            params = params.set('comparable', search.comparable.toString());
        }
        if (!isNullOrUndefined(search.quotable)) {
            params = params.set('quotable', search.quotable.toString());
        }
        if (!isNullOrUndefined(search.withdrawalable)) {
            params = params.set('withdrawalable', search.withdrawalable.toString());
        }
        if (!isNullOrUndefined(search.proposalRPASupported)) {
            params = params.set('proposalRPASupported', search.proposalRPASupported.toString());
        }
        if (!isNullOrUndefined(search.financialPlanSupported)) {
            params = params.set('financialPlanSupported', search.financialPlanSupported.toString());
        }
        if (!isNullOrUndefined(search.premiumFinancingSupported)) {
            params = params.set('premiumFinancingSupported', search.premiumFinancingSupported.toString());
        }
        if (!isNullOrUndefined(search.premiumOffsetSupported)) {
            params = params.set('premiumOffsetSupported', search.premiumOffsetSupported.toString());
        }
        if (!isNullOrUndefined(search.proposalPrepaymentSupported)) {
            params = params.set('proposalPrepaymentSupported', search.proposalPrepaymentSupported.toString());
        }
        if (!isNullOrUndefined(search.hasBrochure)) {
            params = params.set('hasBrochure', search.hasBrochure.toString());
        }
        if (!isNullOrUndefined(search.hot)) {
            params = params.set('hot', search.hot.toString());
        }
        if (!isNullOrUndefined(search.region)) {
            params = params.set('region', search.region.toString());
        }
        return this.http.get<ApiResponse<PagedResp<Product>>>(this.productUrl + '/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    /**
     * 公司列表
     * **/
    companyList(search: ProductCompanySearch): Observable<PagedResp<ProductCompany>> {
        let params = new HttpParams({encoder: new HttpParamEncoder()});        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        if (!isNullOrUndefined(search.name)) {
            params = params.set('name', search.name.toString());
        }
        if (!isNullOrUndefined(search.code)) {
            params = params.set('code', search.code.toString());
        }
        if (!isNullOrUndefined(search.status)) {
            params = params.set('status', search.status.toString());
        }
        if (!isNullOrUndefined(search.hot)) {
            params = params.set('hot', search.hot.toString());
        }
        if (!isNullOrUndefined(search.region)) {
            params = params.set('region', search.region.toString());
        }
        if (!isNullOrUndefined(search.businessType)) {
            params = params.set('businessType', search.businessType.toString());
        }
        return this.http.get<ApiResponse<PagedResp<ProductCompany>>>(this.productUrl + '/company/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    statistic(): Observable<ProductStatistic> {
        return this.http.get<ApiResponse<ProductStatistic>>(this.productUrl + '/statistic', {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    productCreate(product: Product): Observable<Product> {
        return this.http.post<ApiResponse<Product>>(this.productUrl, product, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    productInfo(code: String): Observable<Product> {
        return this.http.get<ApiResponse<Product>>(this.productUrl + '/info/' + code, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return plainToInstance(Product, data.body.data);
        }))
    }

    productUpdate(code: string, product: Product): Observable<Product> {
        return this.http.put<ApiResponse<Product>>(this.productUrl + '/' + code, product, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    productFilesUpdate(code: string, files: Material[]): Observable<Product> {
        return this.http.put<ApiResponse<Product>>(this.productUrl + '/' + code + '/files', files, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    productDelete(code: string): Observable<string> {
        return this.http.delete<ApiResponse<string>>(this.productUrl + '/' + code, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    productMedicalOptionsUpdate(code: string, medicalOptions: MedicalOptions): Observable<string> {
        return this.http.put<ApiResponse<string>>(this.productUrl + '/' + code + "/medical-options", medicalOptions, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    companyInfo(code: string): Observable<ProductCompany> {
        return this.http.get<ApiResponse<ProductCompany>>(this.productUrl + '/company/info/' + code, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return plainToInstance(ProductCompany, data.body.data);
        }));
    }

    companyUpdate(code: string, company: ProductCompany): Observable<ProductCompany> {
        return this.http.put<ApiResponse<ProductCompany>>(this.productUrl + '/company/' + code, company, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return plainToInstance(ProductCompany, data.body.data);
        }));
    }


    companyUpdateTime(code: string): Observable<ProductCompany> {
        return this.http.put<ApiResponse<ProductCompany>>(this.productUrl + '/company/' + code + '/document-inspection-time/update', null, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return plainToInstance(ProductCompany, data.body.data);
        }));
    }

    companyCreditRatingsUpdate(code: string, creditRatings: CreditRating[]): Observable<boolean> {
        return this.http.put<ApiResponse<boolean>>(this.productUrl + '/company/' + code + '/credit-ratings/update', creditRatings, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }));
    }

    companyCreate(productCompany: ProductCompany): Observable<ProductCompany> {
        return this.http.post<ApiResponse<ProductCompany>>(this.productUrl + '/company', productCompany, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }));
    }

    companyDelete(code: string): Observable<string> {
        return this.http.delete<ApiResponse<string>>(this.productUrl + '/company/' + code, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    companyFilesUpdate(code: string, files: Material[]): Observable<boolean> {
        return this.http.put<ApiResponse<boolean>>(this.productUrl + '/company/' + code + '/files', files, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    companyFilesAdd(code: string, file: Material): Observable<boolean> {
        return this.http.post<ApiResponse<boolean>>(this.productUrl + '/company/' + code + '/file/add', file, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    companyFilesRemove(code: string, file: Material): Observable<boolean> {
        return this.http.post<ApiResponse<boolean>>(this.productUrl + '/company/' + code + '/file/remove', file, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    companyLinksUpdate(param: ProductCompany, links: Link[]): Observable<ProductCompany> {
        let company = new ProductCompany();
        company.code = param.code;
        company.status = param.status;
        company.nameI18n = param.nameI18n;
        company.name = param.name;
        company.links = links;
        return this.http.put<ApiResponse<ProductCompany>>(this.productUrl + '/company/' + param.code, company, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    productTagList(search: ProductTagSearch): Observable<PagedResp<ProductTag>> {
        let params = new HttpParams({encoder: new HttpParamEncoder()});        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        return this.http.get<ApiResponse<PagedResp<ProductTag>>>(this.productUrl + '/tag/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    productExport(): Observable<boolean> {
        return this.http.download(this.productUrl + '/export', {
            headers: this.getHeaders(),
            params: null,
            responseType: 'blob',
            observe: 'response'
        }).pipe(map(response => {
            this.http.saveResponseBlob(response);
            return true;
        }))
    }

    productAIExport(): Observable<boolean> {
        return this.http.download(this.productUrl + '/ai-export', {
            headers: this.getHeaders(),
            params: null,
            responseType: 'blob',
            observe: 'response'
        }).pipe(map(response => {
            this.http.saveResponseBlob(response);
            return true;
        }))
    }

    updateExchangeRate(): Observable<boolean> {
        return this.http.get<ApiResponse<boolean>>(this.productUrl + '/company/update-exchange-rate', {
            headers: this.getHeaders(),
            params: null,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    categoryInfo(code: string): Observable<ProductCategory> {
        return this.http.get<ApiResponse<ProductCategory>>(this.productUrl + '/category/info/' + code, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    categoryUpdate(code: string, category: ProductCategory): Observable<ProductCategory> {
        return this.http.put<ApiResponse<ProductCategory>>(this.productUrl + '/category/' + code, category, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    restrictionList(search: ProductRestrictionSearch): Observable<PagedResp<ProductRestriction>> {
        let params = new HttpParams({encoder: new HttpParamEncoder()});        if (!isNullOrUndefined(search.pageNum)) {
            params = params.set('pageNum', search.pageNum.toString());
        }
        if (!isNullOrUndefined(search.pageSize)) {
            params = params.set('pageSize', search.pageSize.toString());
        }
        if (!isNullOrUndefined(search.code)) {
            params = params.set('code', search.code.toString());
        }
        if (!isNullOrUndefined(search.premiumTerm)) {
            params = params.set('premiumTerm', search.premiumTerm.toString());
        }
        if (!isNullOrUndefined(search.type)) {
            params = params.set('type', search.type.toString());
        }

        return this.http.get<ApiResponse<PagedResp<ProductRestriction>>>(this.productUrl + '/restriction/list', {
            headers: this.getHeaders(),
            params: params,
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    // productPropertyInfo(code: string): Observable<ProductPropertyGroup[]> {
    //     return this.http.get<ApiResponse<ProductPropertyGroup[]>>(this.productUrl + '/property/groups/' + code, {
    //         headers: this.getHeaders(),
    //         observe: 'response'
    //     }).pipe(map(data => {
    //         return data.body.data
    //     }))
    // }

    /**
     * 获取产品列表
     * @param search 搜索参数
     */
    productFeaturesUpdate(code: string, feature: ProductFeature): Observable<ProductFeature> {
        return this.http.put<ApiResponse<ProductFeature>>(this.productUrl + '/features/' + code, feature, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    modifyProductFile(code: string, modifyReq: MaterialModifyReq): Observable<boolean> {
        return this.http.put<ApiResponse<boolean>>(this.productUrl + '/' + code + '/file', modifyReq, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    modifyCompanyFile(code: string, modifyReq: MaterialModifyReq): Observable<boolean> {
        return this.http.put<ApiResponse<boolean>>(this.productUrl + '/company/' + code + '/file', modifyReq, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data
        }))
    }

    getAllInstance(code: String): Observable<any> {
        return this.http.get<ApiResponse<any>>(this.productUrl + '/all-instance/' + code, {
            headers: this.getHeaders(),
            observe: 'response'
        }).pipe(map(data => {
            return data.body.data;
        }))
    }

    downloadTemplate(flag: boolean): Observable<boolean> {
        let url = flag ? '/premium_table/download-model' : '/premium_rate/download-model';
        return this.http.download(this.productUrl + url, {
            headers: this.getHeaders(),
            params: null,
            responseType: 'blob',
            observe: 'response'
        }).pipe(map(response => {
            this.http.saveResponseBlob(response);
            return true;
        }))
    }

    companyAIExportLinks(): Observable<boolean> {
        return this.http.download(this.productUrl + '/company/ai-export-company-links', {
            headers: this.getHeaders(),
            params: null,
            responseType: 'blob',
            observe: 'response'
        }).pipe(map(response => {
            this.http.saveResponseBlob(response);
            return true;
        }))
    }

}
